import React from 'react';
import Typical from 'react-typical';

const Typer = ({ data }) => {
  const fillStringsWithTime = (stringsArray) => {
    let i = 0;
    while (i <= stringsArray.length - 1) {
      stringsArray.splice(i + 1, 0, 2000);
      i += 2;
    }
    return stringsArray;
  };

  return <Typical steps={fillStringsWithTime(data)} loop={Infinity} wrapper="p" />;
};

export default Typer;
