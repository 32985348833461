import React from 'react';
import Img from 'gatsby-image';
import { Link } from 'gatsby';
import './card.css';

const Card = ({ data }) => {
  return (
    <Link to={`/project/${data.slug}`} className="card-link">
      <div className="card projectCard">
        <Img alt={data.title} fluid={data.heroImage.fluid} />
        <div className="card-body">
          <h5 className="card-title">{data.title}</h5>
          <div
            dangerouslySetInnerHTML={{
              __html: data.description.childMarkdownRemark.html,
            }}
            className="card-text"
          />
        </div>
      </div>
    </Link>
  );
};

export default Card;
