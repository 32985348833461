/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
author: llirikslon (https://sketchfab.com/llirikslon)
license: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
source: https://sketchfab.com/3d-models/dji-mavic-3-c5a5abae1dea468ab73b1bdc7d616fa6
title: DJI Mavic 3
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export default function Model({ ...props }) {
  const group = useRef();
  const { nodes, materials } = useGLTF("/djidrone.gltf");
  return (
    <group
      scale={0.1}
      rotation={[0.3, 3.4, 0]}
      position={[0, 0, 0]}
      ref={group}
      {...props}
      dispose={null}
    >
      <group rotation={[-Math.PI / 2, 0, 0]}>
        <group rotation={[Math.PI / 2, 0, 0]}>
          <group position={[0, -0.91, 0.59]}>
            <mesh
              geometry={nodes.Object_7.geometry}
              material={nodes.Object_7.material}
            />
          </group>
          <group position={[0, 0.61, -10.57]}>
            <group position={[-0.19, 1.29, -1.88]}>
              <mesh
                geometry={nodes.Object_11.geometry}
                material={nodes.Object_11.material}
              />
            </group>
            <group position={[0, -0.21, -1.13]}>
              <mesh
                geometry={nodes.Object_14.geometry}
                material={nodes.Object_14.material}
              />
            </group>
            <group position={[-0.51, 1.26, -1.44]}>
              <mesh
                geometry={nodes.Object_17.geometry}
                material={nodes.Object_17.material}
              />
            </group>
            <group position={[0, 1.43, 2.27]}>
              <mesh
                geometry={nodes.Object_20.geometry}
                material={nodes.Object_20.material}
              />
            </group>
            <group position={[0, 1.73, 2.01]}>
              <mesh
                geometry={nodes.Object_23.geometry}
                material={nodes.Object_23.material}
              />
            </group>
            <group position={[0, 1.18, 2.35]}>
              <mesh
                geometry={nodes.Object_26.geometry}
                material={nodes.Object_26.material}
              />
            </group>
            <group position={[0, 0.03, 1.56]}>
              <mesh
                geometry={nodes.Object_29.geometry}
                material={nodes.Object_29.material}
              />
            </group>
            <group position={[0, -0.61, 10.57]}>
              <mesh
                geometry={nodes.Object_32.geometry}
                material={nodes.Object_32.material}
              />
            </group>
            <group position={[0, -0.61, 10.57]}>
              <mesh
                geometry={nodes.Object_35.geometry}
                material={nodes.Object_35.material}
              />
            </group>
            <group position={[0, -0.61, 10.57]}>
              <mesh
                geometry={nodes.Object_38.geometry}
                material={nodes.Object_38.material}
              />
            </group>
            <group position={[0.04, -0.25, -1.62]}>
              <mesh
                geometry={nodes.Object_41.geometry}
                material={nodes.Object_41.material}
              />
            </group>
            <group position={[0.13, -0.21, -1.39]}>
              <mesh
                geometry={nodes.Object_44.geometry}
                material={nodes.Object_44.material}
              />
            </group>
            <group position={[0, 0.2, -1.9]}>
              <mesh
                geometry={nodes.Object_47.geometry}
                material={nodes.Object_47.material}
              />
            </group>
            <group position={[0.04, 0.28, -1.9]}>
              <mesh
                geometry={nodes.Object_50.geometry}
                material={materials.material_1}
              />
            </group>
            <group position={[-0.02, 0.02, 0.56]}>
              <mesh
                geometry={nodes.Object_53.geometry}
                material={nodes.Object_53.material}
              />
            </group>
            <group position={[0, 0.03, 0.97]}>
              <mesh
                geometry={nodes.Object_56.geometry}
                material={nodes.Object_56.material}
              />
            </group>
            <mesh
              geometry={nodes.Object_59.geometry}
              material={nodes.Object_59.material}
            />
          </group>
          <group position={[12.75, 0.39, 14.41]} rotation={[2.94, 0.89, -2.91]}>
            <group
              position={[-0.56, 1.25, -6.24]}
              rotation={[3.1, -0.91, -3.03]}
            >
              <group position={[4.3, -2.26, -4.23]}>
                <mesh
                  geometry={nodes.Object_64.geometry}
                  material={nodes.Object_64.material}
                />
              </group>
              <group position={[4.4, -1.58, -4.25]}>
                <mesh
                  geometry={nodes.Object_67.geometry}
                  material={nodes.Object_67.material}
                />
              </group>
              <group position={[4.37, -1.74, -4.24]}>
                <mesh
                  geometry={nodes.Object_70.geometry}
                  material={nodes.Object_70.material}
                />
              </group>
              <group position={[4.42, -1.43, -4.25]}>
                <mesh
                  geometry={nodes.Object_73.geometry}
                  material={nodes.Object_73.material}
                />
              </group>
              <group position={[3.87, -0.71, -3.65]}>
                <mesh
                  geometry={nodes.Object_76.geometry}
                  material={nodes.Object_76.material}
                />
              </group>
              <group position={[5.16, -0.93, -4.89]}>
                <mesh
                  geometry={nodes.Object_79.geometry}
                  material={nodes.Object_79.material}
                />
              </group>
              <group position={[4.48, -0.98, -4.26]}>
                <mesh
                  geometry={nodes.Object_82.geometry}
                  material={nodes.Object_82.material}
                />
              </group>
              <group position={[4.5, -0.91, -4.27]}>
                <mesh
                  geometry={nodes.Object_85.geometry}
                  material={nodes.Object_85.material}
                />
              </group>
              <group position={[9.06, -1.38, -8.54]}>
                <mesh
                  geometry={nodes.Object_88.geometry}
                  material={nodes.Object_88.material}
                />
              </group>
              <mesh
                geometry={nodes.Object_91.geometry}
                material={nodes.Object_91.material}
              />
            </group>
          </group>
          <group
            position={[-16.09, 1.07, -9.83]}
            rotation={[0.02, -0.26, 0.13]}
          >
            <group
              position={[0.03, 1.05, -0.01]}
              rotation={[0.02, 0.26, -0.13]}
            >
              <group
                position={[0.13, -1.3, 0.02]}
                rotation={[2.55, -1.33, 2.57]}
              >
                <mesh
                  geometry={nodes.Object_96.geometry}
                  material={nodes.Object_96.material}
                />
              </group>
              <group position={[0.05, -0.61, 0.01]}>
                <mesh
                  geometry={nodes.Object_99.geometry}
                  material={nodes.Object_99.material}
                />
              </group>
              <group position={[0.07, -0.78, 0.02]}>
                <mesh
                  geometry={nodes.Object_102.geometry}
                  material={nodes.Object_102.material}
                />
              </group>
              <group position={[0.03, -0.48, 0.01]}>
                <mesh
                  geometry={nodes.Object_105.geometry}
                  material={nodes.Object_105.material}
                />
              </group>
              <group position={[0.25, 0.17, -0.85]}>
                <mesh
                  geometry={nodes.Object_108.geometry}
                  material={nodes.Object_108.material}
                />
              </group>
              <group position={[-0.36, 0.12, 0.84]}>
                <mesh
                  geometry={nodes.Object_111.geometry}
                  material={nodes.Object_111.material}
                />
              </group>
              <group position={[0.07, -0.73, 0.02]}>
                <mesh
                  geometry={nodes.Object_114.geometry}
                  material={nodes.Object_114.material}
                />
              </group>
              <group position={[-2.24, 0.05, 5.85]}>
                <mesh
                  geometry={nodes.Object_117.geometry}
                  material={nodes.Object_117.material}
                />
              </group>
              <group position={[2.12, 0.25, -5.85]}>
                <mesh
                  geometry={nodes.Object_120.geometry}
                  material={nodes.Object_120.material}
                />
              </group>
              <mesh
                geometry={nodes.Object_123.geometry}
                material={nodes.Object_123.material}
              />
            </group>
          </group>
          <group
            position={[-12.75, 0.38, 14.41]}
            rotation={[-0.08, 0.38, 0.15]}
          >
            <group
              position={[-2.65, 1.4, -5.68]}
              rotation={[0.03, -0.39, -0.13]}
            >
              <group position={[4.76, -0.88, 4.31]}>
                <mesh
                  geometry={nodes.Object_128.geometry}
                  material={nodes.Object_128.material}
                />
              </group>
              <group position={[4.69, -0.36, 4.3]}>
                <mesh
                  geometry={nodes.Object_131.geometry}
                  material={nodes.Object_131.material}
                />
              </group>
              <group position={[4.66, -0.2, 4.29]}>
                <mesh
                  geometry={nodes.Object_134.geometry}
                  material={nodes.Object_134.material}
                />
              </group>
              <group position={[4.64, -0.05, 4.29]}>
                <mesh
                  geometry={nodes.Object_137.geometry}
                  material={nodes.Object_137.material}
                />
              </group>
              <group position={[5.19, 0.67, 4.89]}>
                <mesh
                  geometry={nodes.Object_140.geometry}
                  material={nodes.Object_140.material}
                />
              </group>
              <group position={[3.9, 0.45, 3.65]}>
                <mesh
                  geometry={nodes.Object_143.geometry}
                  material={nodes.Object_143.material}
                />
              </group>
              <group position={[4.58, 0.39, 4.28]}>
                <mesh
                  geometry={nodes.Object_146.geometry}
                  material={nodes.Object_146.material}
                />
              </group>
              <group position={[4.56, 0.46, 4.27]}>
                <mesh
                  geometry={nodes.Object_149.geometry}
                  material={nodes.Object_149.material}
                />
              </group>
              <group position={[9.06, 1.38, 8.54]}>
                <mesh
                  geometry={nodes.Object_152.geometry}
                  material={nodes.Object_152.material}
                />
              </group>
              <mesh
                geometry={nodes.Object_155.geometry}
                material={nodes.Object_155.material}
              />
            </group>
          </group>
          <group position={[16.09, 1.08, -9.83]} rotation={[3.09, 0.26, -3.01]}>
            <group
              position={[0.01, 0.55, -0.01]}
              rotation={[3.12, -0.26, -3.02]}
            >
              <group position={[-0.1, -0.82, 0.01]}>
                <mesh
                  geometry={nodes.Object_160.geometry}
                  material={nodes.Object_160.material}
                />
              </group>
              <group position={[-0.04, -0.3, 0.01]}>
                <mesh
                  geometry={nodes.Object_163.geometry}
                  material={nodes.Object_163.material}
                />
              </group>
              <group position={[-0.22, 0.65, -0.86]}>
                <mesh
                  geometry={nodes.Object_166.geometry}
                  material={nodes.Object_166.material}
                />
              </group>
              <group position={[0.39, 0.6, 0.83]}>
                <mesh
                  geometry={nodes.Object_169.geometry}
                  material={nodes.Object_169.material}
                />
              </group>
              <group position={[-2.09, 0.73, -5.86]}>
                <mesh
                  geometry={nodes.Object_172.geometry}
                  material={nodes.Object_172.material}
                />
              </group>
              <group position={[-0.03, -0.25, 0.01]}>
                <mesh
                  geometry={nodes.Object_175.geometry}
                  material={nodes.Object_175.material}
                />
              </group>
              <group position={[2.28, 0.53, 5.84]}>
                <mesh
                  geometry={nodes.Object_178.geometry}
                  material={nodes.Object_178.material}
                />
              </group>
              <group position={[0.03, 0.48, -0.01]}>
                <mesh
                  geometry={nodes.Object_181.geometry}
                  material={nodes.Object_181.material}
                />
              </group>
              <group position={[-0.02, -0.14, 0]}>
                <mesh
                  geometry={nodes.Object_184.geometry}
                  material={nodes.Object_184.material}
                />
              </group>
              <mesh
                geometry={nodes.Object_187.geometry}
                material={nodes.Object_187.material}
              />
            </group>
          </group>
          <group position={[-0.09, 1.32, 5.75]}>
            <group position={[0.09, -1.32, -5.75]} scale={[-1, 1, 1]}>
              <mesh
                geometry={nodes.Object_191.geometry}
                material={nodes.Object_191.material}
              />
            </group>
            <group position={[0.09, -1.32, -5.75]}>
              <mesh
                geometry={nodes.Object_194.geometry}
                material={nodes.Object_194.material}
              />
            </group>
            <group position={[3.86, -0.77, 0.38]}>
              <mesh
                geometry={nodes.Object_197.geometry}
                material={nodes.Object_197.material}
              />
            </group>
            <group position={[-16.42, -2.85, -16]}>
              <mesh
                geometry={nodes.Object_200.geometry}
                material={nodes.Object_200.material}
              />
            </group>
            <group position={[-16.52, -2.66, -16.07]}>
              <mesh
                geometry={nodes.Object_203.geometry}
                material={nodes.Object_203.material}
              />
            </group>
            <group position={[-16.62, -2.46, -16.14]}>
              <mesh
                geometry={nodes.Object_206.geometry}
                material={nodes.Object_206.material}
              />
            </group>
            <group position={[-16.55, -2.72, -16.09]}>
              <mesh
                geometry={nodes.Object_209.geometry}
                material={nodes.Object_209.material}
              />
            </group>
            <group position={[-16, -0.31, -15.58]}>
              <mesh
                geometry={nodes.Object_212.geometry}
                material={nodes.Object_212.material}
              />
            </group>
            <group position={[-10.72, -0.38, -14.31]}>
              <mesh
                geometry={nodes.Object_215.geometry}
                material={nodes.Object_215.material}
              />
            </group>
            <group position={[-14.41, -5.65, -15.03]}>
              <mesh
                geometry={nodes.Object_218.geometry}
                material={nodes.Object_218.material}
              />
            </group>
            <group position={[-16.11, -0.87, -15.57]}>
              <mesh
                geometry={nodes.Object_221.geometry}
                material={nodes.Object_221.material}
              />
            </group>
            <group position={[-16.47, -2.63, -16.03]}>
              <mesh
                geometry={nodes.Object_224.geometry}
                material={nodes.Object_224.material}
              />
            </group>
            <group position={[-13.47, -1.3, -14.14]}>
              <mesh
                geometry={nodes.Object_227.geometry}
                material={nodes.Object_227.material}
              />
            </group>
            <group position={[-15.45, -3.75, -15.57]}>
              <mesh
                geometry={nodes.Object_230.geometry}
                material={nodes.Object_230.material}
              />
            </group>
            <group position={[-4.46, 1.12, -11.46]}>
              <mesh
                geometry={nodes.Object_233.geometry}
                material={nodes.Object_233.material}
              />
            </group>
            <group position={[-3.28, 1.33, -11.1]}>
              <mesh
                geometry={nodes.Object_236.geometry}
                material={nodes.Object_236.material}
              />
            </group>
            <group position={[16.59, -2.85, -16]}>
              <mesh
                geometry={nodes.Object_239.geometry}
                material={nodes.Object_239.material}
              />
            </group>
            <group position={[16.69, -2.66, -16.07]}>
              <mesh
                geometry={nodes.Object_242.geometry}
                material={nodes.Object_242.material}
              />
            </group>
            <group position={[16.79, -2.46, -16.14]}>
              <mesh
                geometry={nodes.Object_245.geometry}
                material={nodes.Object_245.material}
              />
            </group>
            <group position={[4.63, 1.12, -11.46]}>
              <mesh
                geometry={nodes.Object_248.geometry}
                material={nodes.Object_248.material}
              />
            </group>
            <group position={[16.17, -0.31, -15.58]}>
              <mesh
                geometry={nodes.Object_251.geometry}
                material={nodes.Object_251.material}
              />
            </group>
            <group position={[10.89, -0.38, -14.31]}>
              <mesh
                geometry={nodes.Object_254.geometry}
                material={nodes.Object_254.material}
              />
            </group>
            <group position={[14.58, -5.65, -15.03]}>
              <mesh
                geometry={nodes.Object_257.geometry}
                material={nodes.Object_257.material}
              />
            </group>
            <group position={[16.28, -0.87, -15.57]}>
              <mesh
                geometry={nodes.Object_260.geometry}
                material={nodes.Object_260.material}
              />
            </group>
            <group position={[16.64, -2.63, -16.03]}>
              <mesh
                geometry={nodes.Object_263.geometry}
                material={nodes.Object_263.material}
              />
            </group>
            <group position={[13.64, -1.3, -14.14]}>
              <mesh
                geometry={nodes.Object_266.geometry}
                material={nodes.Object_266.material}
              />
            </group>
            <group position={[15.62, -3.75, -15.57]}>
              <mesh
                geometry={nodes.Object_269.geometry}
                material={nodes.Object_269.material}
              />
            </group>
            <group position={[16.72, -2.72, -16.09]}>
              <mesh
                geometry={nodes.Object_272.geometry}
                material={nodes.Object_272.material}
              />
            </group>
            <group position={[3.45, 1.33, -11.1]}>
              <mesh
                geometry={nodes.Object_275.geometry}
                material={nodes.Object_275.material}
              />
            </group>
            <group position={[0.09, 4.37, -15.82]}>
              <mesh
                geometry={nodes.Object_278.geometry}
                material={nodes.Object_278.material}
              />
            </group>
            <group position={[3.34, 0.03, -10.87]}>
              <mesh
                geometry={nodes.Object_281.geometry}
                material={nodes.Object_281.material}
              />
            </group>
            <group position={[3.15, 3.41, -17.06]}>
              <mesh
                geometry={nodes.Object_284.geometry}
                material={nodes.Object_284.material}
              />
            </group>
            <group position={[3.25, 3.4, -17.17]}>
              <mesh
                geometry={nodes.Object_287.geometry}
                material={nodes.Object_287.material}
              />
            </group>
            <group position={[2.1, 3.99, -1.04]}>
              <mesh
                geometry={nodes.Object_290.geometry}
                material={nodes.Object_290.material}
              />
            </group>
            <group position={[0.09, 3.95, -2.86]}>
              <mesh
                geometry={nodes.Object_293.geometry}
                material={nodes.Object_293.material}
              />
            </group>
            <group position={[0.09, 0.58, -7.82]}>
              <mesh
                geometry={nodes.Object_296.geometry}
                material={nodes.Object_296.material}
              />
            </group>
            <group position={[0.08, 0.57, 1.66]}>
              <mesh
                geometry={nodes.Object_299.geometry}
                material={nodes.Object_299.material}
              />
            </group>
            <group position={[0.09, 3.92, -9.7]}>
              <mesh
                geometry={nodes.Object_302.geometry}
                material={nodes.Object_302.material}
              />
            </group>
            <group position={[-2.26, 1.84, 0.77]}>
              <mesh
                geometry={nodes.Object_305.geometry}
                material={nodes.Object_305.material}
              />
            </group>
            <group position={[-2.46, 1.83, 0.83]}>
              <mesh
                geometry={nodes.Object_308.geometry}
                material={nodes.Object_308.material}
              />
            </group>
            <group position={[2.63, 1.83, 0.83]}>
              <mesh
                geometry={nodes.Object_311.geometry}
                material={nodes.Object_311.material}
              />
            </group>
            <group position={[0.09, 3.81, -2.09]}>
              <mesh
                geometry={nodes.Object_314.geometry}
                material={nodes.Object_314.material}
              />
            </group>
            <group position={[0.09, 3.38, -17.17]}>
              <mesh
                geometry={nodes.Object_317.geometry}
                material={nodes.Object_317.material}
              />
            </group>
            <group position={[2.43, 1.84, 0.77]}>
              <mesh
                geometry={nodes.Object_320.geometry}
                material={nodes.Object_320.material}
              />
            </group>
            <group position={[0.09, 3.22, 1.28]}>
              <mesh
                geometry={nodes.Object_323.geometry}
                material={nodes.Object_323.material}
              />
            </group>
            <group position={[-0.88, 0.71, 1.94]}>
              <mesh
                geometry={nodes.Object_326.geometry}
                material={nodes.Object_326.material}
              />
            </group>
            <group position={[-0.31, 0.71, 1.95]}>
              <mesh
                geometry={nodes.Object_329.geometry}
                material={nodes.Object_329.material}
              />
            </group>
            <group position={[0.27, 0.71, 1.95]}>
              <mesh
                geometry={nodes.Object_332.geometry}
                material={nodes.Object_332.material}
              />
            </group>
            <group position={[-1.45, 0.71, 1.89]}>
              <mesh
                geometry={nodes.Object_335.geometry}
                material={nodes.Object_335.material}
              />
            </group>
            <group position={[-3.17, 0.03, -10.87]}>
              <mesh
                geometry={nodes.Object_338.geometry}
                material={nodes.Object_338.material}
              />
            </group>
            <group position={[-1.93, 3.99, -1.04]}>
              <mesh
                geometry={nodes.Object_341.geometry}
                material={nodes.Object_341.material}
              />
            </group>
            <group position={[0.09, 3.05, -0.66]}>
              <mesh
                geometry={nodes.Object_344.geometry}
                material={nodes.Object_344.material}
              />
            </group>
            <group position={[-2.57, 3.44, 0.75]}>
              <mesh
                geometry={nodes.Object_347.geometry}
                material={nodes.Object_347.material}
              />
            </group>
            <group position={[0.09, -2.19, -4.8]}>
              <mesh
                geometry={nodes.Object_350.geometry}
                material={nodes.Object_350.material}
              />
            </group>
            <group position={[0.09, -2.71, -8.77]}>
              <mesh
                geometry={nodes.Object_353.geometry}
                material={nodes.Object_353.material}
              />
            </group>
            <group position={[2.74, 3.44, 0.75]}>
              <mesh
                geometry={nodes.Object_356.geometry}
                material={nodes.Object_356.material}
              />
            </group>
            <group position={[2.63, 3.44, 0.64]}>
              <mesh
                geometry={nodes.Object_359.geometry}
                material={nodes.Object_359.material}
              />
            </group>
            <group position={[-3.08, 3.4, -17.17]}>
              <mesh
                geometry={nodes.Object_362.geometry}
                material={nodes.Object_362.material}
              />
            </group>
            <group position={[-2.98, 3.41, -17.06]}>
              <mesh
                geometry={nodes.Object_365.geometry}
                material={nodes.Object_365.material}
              />
            </group>
            <group position={[1.29, 0.71, 1.96]}>
              <mesh
                geometry={nodes.Object_368.geometry}
                material={nodes.Object_368.material}
              />
            </group>
            <group position={[0.09, -2.54, -2.3]}>
              <mesh
                geometry={nodes.Object_371.geometry}
                material={nodes.Object_371.material}
              />
            </group>
            <group position={[0.08, -2.7, -6.8]}>
              <mesh
                geometry={nodes.Object_374.geometry}
                material={nodes.Object_374.material}
              />
            </group>
            <group position={[0.08, -2.7, -2.3]}>
              <mesh
                geometry={nodes.Object_377.geometry}
                material={nodes.Object_377.material}
              />
            </group>
            <group position={[0.08, -2.54, -6.8]}>
              <mesh
                geometry={nodes.Object_380.geometry}
                material={nodes.Object_380.material}
              />
            </group>
            <group position={[-2.46, 3.44, 0.64]}>
              <mesh
                geometry={nodes.Object_383.geometry}
                material={nodes.Object_383.material}
              />
            </group>
            <group position={[0.09, -2.2, -10.23]}>
              <mesh
                geometry={nodes.Object_386.geometry}
                material={nodes.Object_386.material}
              />
            </group>
            <group position={[1.8, -2.6, -1.14]}>
              <mesh
                geometry={nodes.Object_389.geometry}
                material={nodes.Object_389.material}
              />
            </group>
            <group position={[-2.83, 1, -0.38]}>
              <mesh
                geometry={nodes.Object_392.geometry}
                material={nodes.Object_392.material}
              />
            </group>
            <group position={[3, 1, -0.38]}>
              <mesh
                geometry={nodes.Object_395.geometry}
                material={nodes.Object_395.material}
              />
            </group>
            <group position={[-1.63, -2.6, -1.14]}>
              <mesh
                geometry={nodes.Object_398.geometry}
                material={nodes.Object_398.material}
              />
            </group>
            <group position={[-12.43, -2.99, 8.71]}>
              <mesh
                geometry={nodes.Object_401.geometry}
                material={nodes.Object_401.material}
              />
            </group>
            <group position={[-13.15, -2.95, 9.41]}>
              <mesh
                geometry={nodes.Object_404.geometry}
                material={nodes.Object_404.material}
              />
            </group>
            <group position={[-13.29, -2.98, 9.53]}>
              <mesh
                geometry={nodes.Object_407.geometry}
                material={nodes.Object_407.material}
              />
            </group>
            <group position={[-9.07, -1.63, 4.87]}>
              <mesh
                geometry={nodes.Object_410.geometry}
                material={nodes.Object_410.material}
              />
            </group>
            <group position={[-12.92, -3.78, 8.98]}>
              <mesh
                geometry={nodes.Object_413.geometry}
                material={nodes.Object_413.material}
              />
            </group>
            <group position={[-12.55, -1.52, 8.68]}>
              <mesh
                geometry={nodes.Object_416.geometry}
                material={nodes.Object_416.material}
              />
            </group>
            <group position={[-13.16, -3.17, 9.45]}>
              <mesh
                geometry={nodes.Object_419.geometry}
                material={nodes.Object_419.material}
              />
            </group>
            <group position={[-13.25, -2.73, 9.47]}>
              <mesh
                geometry={nodes.Object_422.geometry}
                material={nodes.Object_422.material}
              />
            </group>
            <group position={[-13.21, -2.95, 9.46]}>
              <mesh
                geometry={nodes.Object_425.geometry}
                material={nodes.Object_425.material}
              />
            </group>
            <group position={[-3.69, -0.77, 0.38]}>
              <mesh
                geometry={nodes.Object_428.geometry}
                material={nodes.Object_428.material}
              />
            </group>
            <group position={[13.33, -3.17, 9.45]}>
              <mesh
                geometry={nodes.Object_431.geometry}
                material={nodes.Object_431.material}
              />
            </group>
            <group position={[13.42, -2.73, 9.47]}>
              <mesh
                geometry={nodes.Object_434.geometry}
                material={nodes.Object_434.material}
              />
            </group>
            <group position={[13.38, -2.95, 9.46]}>
              <mesh
                geometry={nodes.Object_437.geometry}
                material={nodes.Object_437.material}
              />
            </group>
            <group position={[12.6, -2.99, 8.71]}>
              <mesh
                geometry={nodes.Object_440.geometry}
                material={nodes.Object_440.material}
              />
            </group>
            <group position={[13.32, -2.95, 9.41]}>
              <mesh
                geometry={nodes.Object_443.geometry}
                material={nodes.Object_443.material}
              />
            </group>
            <group position={[13.46, -2.98, 9.53]}>
              <mesh
                geometry={nodes.Object_446.geometry}
                material={nodes.Object_446.material}
              />
            </group>
            <group position={[9.24, -1.63, 4.87]}>
              <mesh
                geometry={nodes.Object_449.geometry}
                material={nodes.Object_449.material}
              />
            </group>
            <group position={[13.09, -3.78, 8.98]}>
              <mesh
                geometry={nodes.Object_452.geometry}
                material={nodes.Object_452.material}
              />
            </group>
            <group position={[12.72, -1.52, 8.68]}>
              <mesh
                geometry={nodes.Object_455.geometry}
                material={nodes.Object_455.material}
              />
            </group>
          </group>
        </group>
      </group>
    </group>
  );
}

useGLTF.preload("/djidrone.gltf");
