import React, { Suspense } from "react";
import { graphql } from "gatsby";
import get from "lodash/get";
import { Helmet } from "react-helmet";
import Layout from "../components/layout";
import Card from "../components/card/card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Djidrone from "../components/3D/Djidrone";
import { Canvas } from "@react-three/fiber";
import { OrbitControls } from "@react-three/drei";
import Typer from "../components/typer/typer";

class RootIndex extends React.Component {
  render() {
    const projects = get(this, "props.data.allContentfulProjectPost.edges");
    const [author] = get(this, "props.data.allContentfulPerson.edges");
    console.log(author);
    const splitToStringArray = (string) => {
      return string.split("\n");
    };

    return (
      <Layout location={this.props.location}>
        <Helmet>
          <title>
            {get(this, "props.data.site.siteMetadata.title")} - Projects and
            Biography
          </title>
          <meta
            name="description"
            content={get(this, "props.data.site.siteMetadata.description")}
          />
          <meta
            name="image"
            property="og:image"
            content="https://ruzauskas.eu/Savanoriai.png"
          />
        </Helmet>
        <div style={{ background: "#fff" }}>
          <Canvas camera={{ fov: 75, position: [0, 0, 4] }} className="canvas">
            <OrbitControls enableZoom={false} />
            <ambientLight intensity={1} />
            <directionalLight position={[-2, 5, 2]} intensity={5} />
            <Suspense fallback="Loading drone...">
              <Djidrone />
            </Suspense>
          </Canvas>
          <div className={"heroDetails"}>
            <h3 className={"heroHeadline"}>{author.node.name}</h3>
            <p className={"heroTitle"}>{author.node.title}</p>
            <Typer data={splitToStringArray(author.node.shortBio.shortBio)} />
          </div>
          <h2 className="section-headline">Recent projects</h2>
          <Row>
            {projects.map(({ node }) => {
              return (
                <Col key={node.slug} lg={4}>
                  <Card data={node} />
                </Col>
              );
            })}
          </Row>
        </div>
      </Layout>
    );
  }
}

export default RootIndex;

export const pageQuery = graphql`
  query HomeQuery {
    allContentfulProjectPost(sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          title
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          heroImage {
            fluid(maxWidth: 350) {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    allContentfulPerson(
      filter: { contentful_id: { eq: "15jwOBqpxqSAOy2eOO4S0m" } }
    ) {
      edges {
        node {
          name
          shortBio {
            shortBio
          }
          title
          heroImage: image {
            fluid(
              maxWidth: 1180
              maxHeight: 480
              resizingBehavior: PAD
              background: "rgb:000000"
            ) {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`;
